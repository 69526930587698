<template>
    
        <div class="modal-dialog onload-modal  modal-dialog-centered">
            <div class="modal-content">
                <div class="container padding-here">
                    <div class="recent-bank-sec bg-wrong">
                        <div class="recent-bank">
                            <div class="bank-icon">
                                <img loading="lazy" src="@/assets/images/wrong-icon.webp" alt="">
                            </div>
                            <div class="bank-con">
                                <h6>{{this.translatedLangData('wrong','Wrong')}}!</h6>
                                <span>{{ message }}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

</template>
<script>
export default {
    name: "ErrorModal",
    props: ['message'],
    inject:['translatedLangData'],
}
</script>

<style scoped>
.padding-here{
    padding: 0px !important;
}
</style>